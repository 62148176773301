import React, { Component, PureComponent } from 'react';
import { Link } from 'react-router-dom'; // TODO: should not use direct link
import classNames from 'classnames';
import { useIndexToId } from '../../hooks/select';
import AppHeader from '../../components/v5/AppHeader';
import ToggleSideBar from '../../components/v5/ToggleSideBar';
import ReactPaginate from 'react-paginate';
import TruncateText from '../../components/v5/TruncateText';
import CFlag, { NoCFlag } from '../../reactcomponents/CFlag';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import WorkflowActions from '../../components/v5/WorkflowActions';
import { isDesktopMode
	, cleanChannelIdentifier
	, isValidTelavoxUrl
} from '../../common/v5/helpers';
import { pathPrefix } from '../../common/v5/config';
import {
	SingleSelectDD,
	SortFilterDropdown
} from '../../reactcomponents/Dropdown';
import {
	AOT_SHOW_INACTIVE,
	DDG_ADVANCE,
	DDG_ERRAND_TYPE,
	DDG_INTERVAL_DATE,
	DDG_MULTI_AGENTS,
	DDG_MULTI_CONNECTEDAREAS,
	DDG_MULTI_CHANNELS,
	DDG_MULTI_TAGS,
	DDG_MULTI_SIMPLE_TAGS,
	DDG_SORT_ATTR,
	DDG_SORT_DIRECTION,
	DDG_DATE_FROM,
	DDG_DATE_TO,
	FILTER_SEARCH_ERRAND_EMPTY,
	FILTER_SEARCH_ERRAND_MY,
	FILTER_SEARCH_ERRAND_ALL,
	FILTER_SEARCH_ERRAND_CLOSE,
	FILTER_SEARCH_SORT_ATTRIBUTES as dataSortAttributes,
	FILTER_SEARCH_SORT_DIRECTION as dataSortDirections,
	SEARCH_ERRANDS,
	MM_SEARCH
} from '../../common/v5/constants';
import 'jquery-ui-bundle';
import "jquery";
import 'bootstrap';
import { Button } from 'reactstrap';
import {I} from '../../common/v5/config.js';
import {
	WFP_OLD_CHAT
} from '../../common/v5/constants';
import { Dropdowns } from '../../components/v5/DropdownGroup';
import Anchor from '../../reactcomponents/Anchor';
import ProfilePhoto from '../../reactcomponents/ProfilePhoto';
import { SidebarSettingsCtnr, WorkflowBreadcrumbsCtnr, WorkflowInnerContainerCtnr, IconizeSideBarMenuCtnr } from './workflowCtnr';
import { humanByteSize } from '../../common/v5/helpers.js';
import { patchActivateExport } from '../../redux/actions/async/ajax';
import { ButtonIcon } from '../../reactcomponents/HeaderActions';
import MenuCtnr from '../../common/v5/menuCtnr';
import Measure from 'react-measure';
import { isMobile } from '../../redux/util';

const searchDDData = {
		data0: DDG_DATE_FROM
		, data1: DDG_DATE_TO
		, data2: DDG_MULTI_CONNECTEDAREAS
		, data3: DDG_MULTI_AGENTS
		, data4: DDG_MULTI_CHANNELS
		, data5: DDG_MULTI_SIMPLE_TAGS
		, data6: DDG_ERRAND_TYPE
		, data10: DDG_ADVANCE
	}
	, searchMixData = {mixed8: true}
	;

const SearchInfo = ({ label, searchPrefix, style, value, ...props }) => {
	return (
		<div className={"search-info " + searchPrefix}>
			<div style={style} title={value}><label>{label}:</label><span data-qa-id={searchPrefix}>{value}</span></div>
		</div>
	)
}

const topResultsSelection = [
	{ id: 10, value: 10 },
	{ id: 15, value: 15 },
	{ id: 17, value: 17 },
	{ id: 25, value: 25 },
	{ id: 35, value: 35 },
	{ id: 50, value: 50 }
]

const idValue = { id: 'id', name: 'value' }

const SelectTopResult = ({ onSelect, onToggle, selected, show }) => (
	<ul className="sidebar-dropdown-menu">
		<li>
			<SingleSelectDD
				id="topList"
				idFields={idValue}
				isHeader={false}
				data={topResultsSelection}
				onSelect={onSelect}
				onToggle={onToggle}
				selected={selected}
				show={show}
				showChevron={true}
				title={I('Top results {COUNT}').replace('{COUNT}',  selected)}
			/>
		</li>
	</ul>
)

class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ready: false,
			showFilterMobile : false,
			showCollapsedSearchFolder: false,
			previewDimensions: ""
		};
		this.toggleOldChat = this.toggleOldChat.bind(this);
		this.handleChangeFromDate = this.handleChangeFromDate.bind(this);
		this.handleChangeToDate = this.handleChangeToDate.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.handleSelectAgents = this.handleSelectAgents.bind(this);
		this.handleSelectChannels = this.handleSelectChannels.bind(this);
		this.handleSelectTags = this.handleSelectTags.bind(this);
		this.onSearchClick = this.onSearchClick.bind(this);
		this.handleSelectErrandTypes = this.handleSelectErrandTypes.bind(this);
		this.handleLinkOnClick = this.handleLinkOnClick.bind(this);
		this.getClientAvatar = this.getClientAvatar.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleShowSearchList = this.handleShowSearchList.bind(this);
		this.handleHideSearchList = this.handleHideSearchList.bind(this);
		this.handleMessageEvent = this.handleMessageEvent.bind(this);
	}
	componentDidMount() {
		let carryHeaderTerm = "";
		if(this.props.carryHeaderTerm != ""){
			carryHeaderTerm = this.props.carryHeaderTerm;
		}else if(this.props.searchTerm!=""){
			carryHeaderTerm = this.props.searchTerm;
		}
		this.props.onLoad(this.props.wfSettings, carryHeaderTerm, this.props.current);
		window.addEventListener('resize', this.handleWorkflowResize);
		window.addEventListener('message', this.handleMessageEvent);
		if (this.props.ui.showMobileView){
			this.props.onToggleSideBar(false);
		}
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWorkflowResize);
		window.removeEventListener('message', this.handleMessageEvent);
		if(this.timeout){
			clearTimeout(this.timeout);
		}
	}
	toggleOldChat() {
		this.props.onToggleWorkflowPopup(WFP_OLD_CHAT, !this.props.showOldChat);
	}
	handleWorkflowResize = () => {
		if(isMobile) {
			this.props.onSetMobileView(true);
			this.props.onToggleSideBar(false);
		}else{
			this.props.onSetMobileView(false);
			this.props.onSetActivateErrandMobileView(false);
			this.props.onToggleSideBar(true);
		}
	}

	handleMessageEvent(e) {
		if(typeof e.data === "object"){
			if(typeof e.data.action !== 'undefined' &&
				e.data.action !== null){
				if(e.data.action == "open-errand"){
					let urlValid = isValidTelavoxUrl(e.origin);
					if(typeof e.data.params !== 'undefined' &&
						e.data.params !== null && urlValid == true){
						externalqueue.isPostMessage = true;
						if(typeof e.data.params.deepLink !== 'undefined' &&
							e.data.params.deepLink !== null &&
							e.data.params.deepLink !== ""){
								let encoded = e.data.params.deepLink;
								this.props.simpleLoadAndOpen(encoded,
									this.props.currentOpened);
						}
					}
				} else {
					console.info("unhandled messsage action:", e.data.action);
				}
			}
		}
	}

	handleSideBarToggle = (e) => {
		this.props.onToggleSideBar(!this.props.ui.showSideBar);
	}
	handleSideBarCollapse = (e) => {
		this.props.onCollapseSideBar(!this.props.ui.collapseSideBar);
	}
	handleKeyPress = (e) =>{
		if(e.keyCode == 13){
			this.props.onHandleSearch(this.props.searchText);
		}
	}
	handleToggle = () =>{
		this.props.onHandleToplistToggle(!this.props.searchUI.showTopList);
	}
	onSelectTopList = (v) =>{
		this.props.onHandleTopListSelect(v);
	}
	handleMobileFilter = ()=>{
		this.setState({
			showFilterMobile : !this.state.showFilterMobile
		})
	}
	render () {
		const p = this.props, ui = p.ui;
		const {
			searchBoxEmpty,
			topResults,
			showTopList,
			isProcessing
		} = this.props.searchUI;
		const {
			onHandleClear,
			onChangeSearchText,
			searchFilter,
			searchResults,
			searchCheckboxStates,
			wfSettings
		} = this.props;
		let layoutClass = "layout slim";
		let hide = true;
		let backNavHide = true;
		let hideSideAndConv = errandViewOnly;
		if(ui.showMobileView){
			hide = true;
			if(ui.showSideBar) {
				hide = false;
				// layoutClass = "layout closed-sidebar";
			}
			if(ui.showErrandMobileView) {
				 backNavHide = false;
				if(ui.showSideBar){
					backNavHide = true;
				}
			}
		}else{
			hide = false;
			if(!ui.showSideBar) {
				layoutClass = "layout slim closed-sidebar";
			}
		}
		if(p.activateChat) {
			layoutClass += " opened-chat";
		}

		let totalResults = 0;
		if(searchFilter.isErrands){
			totalResults = searchResults.totalErrand;
		}else if(searchFilter.isCollaborations){
			totalResults = searchResults.totalCollabErrands;
		}else if(searchFilter.isContactCard){
			totalResults = searchResults.totalContacts;
		}else if(searchFilter.isAreas){
			totalResults = searchResults.totalAreas;
		}else if(searchFilter.isKnowledgeBase){
			totalResults = searchResults.totalKBs;
		}else if(searchFilter.isTags){
			totalResults = searchResults.totalTags;
		}else if(searchFilter.isEEContacts){
			totalResults = searchResults.totalExternalExpertContacts;
		}else if(searchFilter.isCustomerNotes){
			totalResults = searchResults.totalCustomerNotes;
		}else if(searchFilter.isErrandNotes){
			totalResults = searchResults.totalErrandNotes;
		}else{
			totalResults = searchResults.totalErrand + searchResults.totalCollabErrands + searchResults.totalAreas + searchResults.totalContacts + searchResults.totalKBs + searchResults.totalTags + searchResults.totalExternalExpertContacts + searchResults.totalCustomerNotes + searchResults.totalErrandNotes;
		}
		let sidebarClass = "";
		if(ui.collapseSideBar){
			sidebarClass = "collapsed";
		}
		let customStyledAppHeader = { borderRadius: "initial"}
		return(
			<div className="search-page">
				<div id="page-wrapper">
					<section className={layoutClass} data-app-layout>
						{hideSideAndConv == false &&
						<div className={classNames("app-sidebar", sidebarClass)}>
							<AppHeader backNavHide={backNavHide}
								toggleErrandList={this.toggleErrandList}
								showErrandMobileView={!ui.showErrandMobileView}
								showSideBar={ui.showSideBar}
								collapseSideBar={ui.collapseSideBar}
								showMobileView={ui.showMobileView}
								handleSideBarToggle={this.handleSideBarToggle}
								handleSideBarCollapse={this.handleSideBarCollapse}
								onMainView={this.props.onMainView}
							/>
							{this.searchSidebar(hide)}
						</div>
						}
						<div className={classNames("app-content width-100 search-main-content", {"collapsed" : ui.collapseSideBar})}>
							<div className="app-header" style={customStyledAppHeader}>
								<ul className="conversations-nav">
									<ToggleSideBar
										data-qa-id={"toggle-sidebar-search"}
										visible={!ui.showSideBar || ui.collapseSideBar}
										collapsed={ui.collapseSideBar}
										showSideBar={ui.showSideBar}
										wrapped={true}
										onClick={this.handleSideBarToggle}
										onCollapse={this.handleSideBarCollapse} />
								</ul>
								<div className="app-conversations hidden">
									<div className="app-header">
										<ul className="conversations-nav">
											<ToggleSideBar
												data-qa-id={"toggle-sidebar-search"}
												visible={!ui.showSideBar || ui.collapseSideBar}
												collapsed={ui.collapseSideBar}
												wrapped={true}
												onClick={this.handleSideBarToggle}
												onCollapse={this.handleSideBarCollapse} />
										</ul>
									</div>
								</div>
								{(ui.collapseSideBar || !ui.showSideBar) && <WorkflowBreadcrumbsCtnr /> }
							</div>
							<WorkflowInnerContainerCtnr className="app-inner-content">
								<div className="errand-box-search filter">
									<div className="search-block-page">
										<div className="search-form">
											<input data-qa-id="search-input" type="text" onChange={onChangeSearchText} id="search_chat" placeholder={I("Search for...")} value={p.searchText} onKeyDown={this.handleKeyPress}/>
											{searchBoxEmpty &&<div className="clear-search" onClick={onHandleClear}></div>}
										</div>
										<ButtonIcon
											className={classNames("btn toggle-recipient", {"active" : this.state.showFilterMobile})}
											hidden={!ui.showMobileView}
											icon={"fas fa-filter"}
											onClick={this.handleMobileFilter}
											data-qa-id={"mobile-btn-toggleSearchFilter"}
											noTooltip={true}
										/>
										{totalResults !== 0 &&
											<div className="search-result-qty">
												{
													totalResults+" "+I("results")
												}
											</div>
										}
										{isProcessing && <div className="search-result-spinner"><i className="fa fa-spinner fa-spin" aria-hidden="true" /></div>}
										<SelectTopResult
											onSelect={this.onSelectTopList}
											onToggle={this.handleToggle}
											selected={topResults}
											show={showTopList}
										/>
									</div>
									<div className="search-block-filter">
										<div className="search-form search-filter">
											{this.searchFilter(topResults)}
										</div>
									</div>
								</div>
								<div className="errand-box">
									<div className="errand-box-search" data-custom-scrollbar>
										{this.searchResults(topResults)}
									</div>
								</div>
							</WorkflowInnerContainerCtnr>
						</div>
					</section>
				</div>
			</div>
		)
	}
	searchResults =(topResults)=>{
		let p = this.props;
		let totalErrands = p.searchResults.totalErrand;
		let totalCollabErrands = p.searchResults.totalCollabErrands;
		let totalAreas = p.searchResults.areas.length;
		let totalTags = p.searchResults.tags.length;
		let totalKBs = p.searchResults.knowledgeBases.length;
		let totalCCs = p.searchResults.contactCards.length;
		let totalEEContacts = p.searchResults.externalExpertContacts.length;
		let totalCustomerNotes = p.searchResults.customerNotes.length;
		let totalErrandNotes = p.searchResults.errandNotes.length;
		let current = p.searchFilter;
		let allErrand = topResults, allArea = topResults;
		if(p.searchUI.showAllArea){
			allArea = p.searchResults.areas.length;
		}
		if(current.isGlobal){
			return(<div>
					{totalErrands > 0 && this.errandSearchResults(allErrand, p.searchResults.errands, p.searchText, I("Errands"))}
					{totalCollabErrands > 0 && this.collabErrandSearchResults(topResults, p.searchResults.collabErrands, p.searchText, I("Collaborations"))}
					{totalCCs > 0 && this.CCSearchResults(topResults, p.searchResults.contactCards, p.searchText, I("Customer Contacts"))}
					{totalAreas > 0 && this.areaSearchResults(allArea, p.searchResults.areas, p.searchText, I("Areas"))}
					{totalKBs > 0 && this.KBSearchResults(topResults, p.searchResults.knowledgeBases, p.searchText, I("Knowledge bases"))}
					{totalTags > 0 && this.tagSearchResults(topResults, p.searchResults.tags, p.searchText, I("Tags"))}
					{totalEEContacts > 0 && this.EESearchResults(topResults, p.searchResults.externalExpertContacts, p.searchText, I("External Expert Contacts"))}
					{totalCustomerNotes > 0 && this.CCNotesSearchResults(topResults, p.searchResults.customerNotes, p.searchText, I("Customer notes"))}
					{totalErrandNotes > 0 && this.ErrandNotesSearchResults(topResults, p.searchResults.errandNotes, p.searchText, I("Internal Comments"))}
				</div>);
		}else{
			if(current.isErrands)
				return(<div>{totalErrands > 0 && this.errandSearchResults(allErrand, p.searchResults.errands, p.searchText, I("Errands"))}</div>)
			else if(current.isCollaborations){
				return(<div>{totalCollabErrands > 0 && this.collabErrandSearchResults(topResults, p.searchResults.collabErrands, p.searchText, I("Collaborations"))}</div>)
			} else if(current.isContactCard)
				return(<div>{totalCCs > 0 && this.CCSearchResults(topResults, p.searchResults.contactCards, p.searchText, I("Customer Contacts"))}</div>)
			else if(current.isKnowledgeBase)
				return(<div>{totalKBs > 0 && this.KBSearchResults(topResults, p.searchResults.knowledgeBases, p.searchText, I("Knowledge bases"))}</div>)
			else if(current.isAreas)
				return(<div>{totalAreas > 0 && this.areaSearchResults(allArea, p.searchResults.areas, p.searchText, I("Areas"))}</div>)
			else if(current.isTags)
				return(<div>{totalTags > 0 && this.tagSearchResults(topResults, p.searchResults.tags, p.searchText, I("Tags"))}</div>)
			else if(current.isEEContacts)
				return(<div>{totalEEContacts > 0 && this.EESearchResults(topResults, p.searchResults.externalExpertContacts, p.searchText, I("External Expert Contacts"))}</div>)
			else if(current.isCustomerNotes)
				return(<div>{totalCustomerNotes > 0 && this.CCNotesSearchResults(topResults, p.searchResults.customerNotes, p.searchText, I("Customer notes"))}</div>)
			else if(current.isErrandNotes)
				return(<div>{totalErrandNotes > 0 && this.ErrandNotesSearchResults(topResults, p.searchResults.errandNotes, p.searchText, I("Internal Comments"))}</div>)
			else
				return(null)
		}
	}
	handleActionToggling = (opt) =>{
		const {
			searchFilter
		} = this.props;
		switch(opt){
			case 'showAdvanceOpts':
				this.props.onHandleSearchFilter(opt, !searchFilter.showAdvanceOpts);
				break;
			case 'showAreaDD':
				this.props.onHandleSearchFilter(opt, !searchFilter.showAreaDD);
				break;
			case 'showAgentDD':
				this.props.onHandleSearchFilter(opt, !searchFilter.showAgentDD);
				break;
			case 'showChannelDD':
				this.props.onHandleSearchFilter(opt, !searchFilter.showChannelDD);
				break;
			case 'showTagsDD':
				this.props.onHandleSearchFilter(opt, !searchFilter.showTagsDD);
				break;
			case 'errandTypesDD':
				this.props.onHandleSearchFilter(opt, !searchFilter.errandTypesDD);
				break;
			case 'sortDirectionDD':
				this.props.onHandleSearchFilter(opt, !searchFilter.sortDirectionDD);
				break;
			case 'errandSortByDD':
				this.props.onHandleSearchFilter(opt, !searchFilter.errandSortByDD);
				break;
			case 'showDateFrom':
				this.props.onHandleSearchFilter(opt, !searchFilter.showDateFrom);
				break;
			case 'showDateTo':
				this.props.onHandleSearchFilter(opt, !searchFilter.showDateTo);
			default:
				console.log('Dbg: unknown options');
		}
	}
	searchFilter() {
		if (this.props.searchFilter.isErrands || this.props.searchFilter.isCollaborations)
			return this.searchDropdowns();
		else
			return null;
	}
	handleChangeFromDate(date) {
		this.props.onChangeFromDate(date, this.props.filterParams.date_to,
			this.props.wfSettings.disableActiveSearch);
	}
	handleChangeToDate(date) {
		this.props.onChangeToDate(date, this.props.filterParams.date_from,
			this.props.wfSettings.disableActiveSearch);
	}
	handleSelectArea(data) {
		this.props.handleSelectArea(data,
			this.props.wfSettings.disableActiveSearch)
	}
	handleSelectAgents(data) {
		this.props.handleSelectAgents(data,
			this.props.wfSettings.disableActiveSearch)
	}
	handleSelectChannels(data) {
		this.props.handleSelectChannels(data,
			this.props.wfSettings.disableActiveSearch)
	}
	handleSelectTags(data) {
		this.props.handleSelectTags(data,
			this.props.wfSettings.disableActiveSearch)
	}
	handleSelectErrandTypes(data) {
		this.props.handleSelectErrandTypes(data,
			this.props.wfSettings.disableActiveSearch)
	}
	handleResetFilters = () => {
		this.props.onSearchResetFilter(this.props.defaultFilterParams);
	}
	onSearchClick(){
		this.props.handleSearchButton();
	}
	searchDropdowns() {
		const {
				searchFilter: filter
				, filterParams: params
				, handleSortDirection
				, handleSelectAdvancedOpt
				, handleSortAttribute
				, ui
				, defaultFilterParams
			} = this.props
			;
		let includeInactive
			, show = {}
			, selected = {}
			, onToggle = {}
			, onSelect = {}
			, doNotRender = {}
			, others = {}
			, sortDirection = this.props.wfSettings["searchSortDirection"]
			, sortAttribute = this.props.wfSettings["searchSortAttribute"]
			, disableActiveSearch = this.props.wfSettings.disableActiveSearch
			;
		if (params.advancedOptions) {
			$.each(params.advancedOptions.split(','), (i,v) => {
				v = parseInt(v, 10);
				if (v === AOT_SHOW_INACTIVE) {
					includeInactive = true;
				}
			});
		}
		//dropdown filters
		let regularFilters = [
			params.date_from,
			params.date_to,
			params.tags,
			params.errandTypes,
			params.showInactive
		];
		let preloadedFilters = {
			areas: params.areas,
			agents: params.agents,
			channels: params.channels
		};
		let showReset = false;
		//if regular filter has value, then means its selected, so we show reset
		for (let i = 0; i < regularFilters.length; i++) {
			if(regularFilters[i]) {
				showReset = true;
			}
		}
		//if preloaded value not same as default then means changed, so show reset
		if(JSON.stringify(preloadedFilters) !== JSON.stringify(defaultFilterParams)) {
			showReset = true;
		};
		//take account of the numbers to match accordingly with the defined const dataX variable's number (searchDDData) for dropdown
		show.show0 = filter.showDateFrom;
		show.show1 = filter.showDateTo;
		show.show2 = filter.showAreaDD;
		show.show3 = filter.showAgentDD;
		show.show4 = filter.showChannelDD;
		show.show5 = filter.showTagsDD;
		show.show6 = filter.errandTypesDD;
		show.show7 = filter.sortDirectionDD;
		show.show8 = {show: params.dateIntervalToggle};
		show.show9 = filter.errandSortByDD;
		show.show10 = filter.showAdvanceOpts;

		selected.selected0 = params.date_from;
		selected.selected1 = params.date_to;
		selected.selected2 = params.areas;
		selected.selected3 = params.agents;
		selected.selected4 = params.channels;
		selected.selected5 = params.tags;
		selected.selected6 = params.errandTypes;
		selected.selected7 = sortDirection;
		selected.selected8 = {
			selectedFromDate: params.date_from
			, selectedToDate: params.date_to
		};
		selected.selected9 = sortAttribute;
		selected.selected10 = params.advancedOptions;

		onToggle.onToggle0 = this.handleActionToggling;
		onToggle.onToggle1 = this.handleActionToggling;
		onToggle.onToggle2 = this.handleActionToggling;
		onToggle.onToggle3 = this.handleActionToggling;
		onToggle.onToggle4 = this.handleActionToggling;
		onToggle.onToggle5 = this.handleActionToggling;
		onToggle.onToggle6 = this.handleActionToggling;
		onToggle.onToggle7 = this.handleActionToggling;
		onToggle.onToggle8 = {onToggle: this.toggleDateInterval};
		onToggle.onToggle9 = this.handleActionToggling;
		onToggle.onToggle10 = this.handleActionToggling;

		onSelect.onSelect0 = this.handleChangeFromDate;
		onSelect.onSelect1 = this.handleChangeToDate;
		onSelect.onSelect2 = this.handleSelectArea;
		onSelect.onSelect3 = this.handleSelectAgents;
		onSelect.onSelect4 = this.handleSelectChannels;
		onSelect.onSelect5 = this.handleSelectTags;
		onSelect.onSelect6 = this.handleSelectErrandTypes;
		onSelect.onSelect7 = handleSortDirection;
		onSelect.onSelect8 = {
			onChangeFromDate: this.handleChangeFromDate
			, onChangeToDate: this.handleChangeToDate
		};
		onSelect.onSelect9 = handleSortAttribute;
		onSelect.onSelect10 = handleSelectAdvancedOpt;

		others.others0 = {id: 'showDateFrom'};
		others.others1 = {id: 'showDateTo'};
		others.others2 = {id: 'showAreaDD', includeInactive, groupSelect: true};
		others.others3 = {id: 'showAgentDD', includeNoOwner: true, includeInactive};
		others.others4 = {id: 'showChannelDD'};
		others.others5 = {id: 'showTagsDD'};
		others.others6 = {id: 'errandTypesDD'};
		others.others7 = {id: 'sortDirectionDD'};
		others.others9 = {id: 'errandSortByDD'};
		others.others10 = {id: 'showAdvanceOpts'};

		return (
			<div className="search-form search-filter wrapper">
				<Dropdowns
					className={classNames("errand-search-selection", {"active" : this.state.showFilterMobile, "mobile" : ui.showMobileView})}
					dataSize={11}
					uppercase={true}
					mobile={ui.showMobileView}
					{...searchMixData}
					{...searchDDData}
					{...show}
					{...selected}
					{...onToggle}
					{...onSelect}
					{...doNotRender}
					{...others}
				/>
				{showReset && <Button color="link" className="btn-reset-filter" onClick={this.handleResetFilters}>{I("Reset All")}</Button>}
				{disableActiveSearch &&
					<button title={I("Reload")} className="search-button" type="submit" onClick={this.onSearchClick}>
						<i className="icon-refresh"></i>
					</button>
				}
				<SortFilterDropdown handleSortDirection={handleSortDirection}
					handleSortAttribute={handleSortAttribute}
					currentSortAttribute={sortAttribute}
					currentSortDirection={sortDirection}
					dataSortAttributes={dataSortAttributes}
					dataSortDirections={dataSortDirections}
				/>
			</div>
		);
	}
	handleFromDateChange = (date) =>{
		let fromDate = "";
		if(date){
			fromDate = date.format("YYYY/MM/DD");
		}
		let toDate = this.props.filterParams.date_to;
		this.props.onChangeFromDate(fromDate, toDate);
	}
	handleToDateChange = (date) =>{
		let toDate = "";
		if(date){
			toDate = date.format("YYYY/MM/DD");
		}
		let fromDate = this.props.filterParams.date_from;
		this.props.onChangeToDate(toDate, fromDate);
	}
	toggleDateInterval = () =>{
		let p = this.props;
		p.toggleDateInterval(!p.filterParams.dateIntervalToggle);
	}
	setCurrentSearch = (e) =>{
		var clickedLink = e.currentTarget;
		var data = clickedLink.getAttribute('data-value');
		this.props.setCurrentSearchOpt(data);
	}
	handleShowSearchList() {
		this.setState({ showCollapsedSearchFolder: true })
	}
	handleHideSearchList() {
		this.setState({ showCollapsedSearchFolder: false })
	}
	loadCollapseSearchFolder(current, searchResults) {
		const s = searchResults;
		return <div className="collapse-folders" id="collapse-folders" onMouseEnter={this.handleShowSearchList} onMouseLeave={this.handleHideSearchList}>
					<div><i className="icon-search-errands"></i></div>
					<div className="collapse-tooltip-folders" hidden={!this.state.showCollapsedSearchFolder}>
						<ul className="collapse-folders-list">
							<li className={current.isErrands ? "current": ""}><a data-qa-id="search_menu_errands" onClick={this.setCurrentSearch} data-value="Errands">{I('Errands')} ({s.totalErrand})</a></li>
							<li className={current.isCollaborations ? "current": ""}><a data-qa-id="search_menu_collaborations" onClick={this.setCurrentSearch} data-value="Collaborations">{I('Collaborations')} ({s.totalCollabErrands})</a></li>
							<li className={current.isContactCard ? "current": ""}><a data-qa-id="search_menu_contact" onClick={this.setCurrentSearch} data-value="CustomerContacts">{I('Customer Contacts')} ({s.totalContacts})</a></li>
							{
								features["library.admin"] &&
								<li className={current.isKnowledgeBase ? "current": ""}><a data-qa-id="search_menu_library" onClick={this.setCurrentSearch} data-value="KnowledgeBase">{I('Knowledge base')} ({s.totalKBs})</a></li>
							}
							<li className={current.isAreas ? "current": ""}><a data-qa-id="search_menu_areas" onClick={this.setCurrentSearch} data-value="Areas">{I('Areas')} ({s.totalAreas})</a></li>
							<li className={current.isTags ? "current": ""}><a data-qa-id="search_menu_tags" onClick={this.setCurrentSearch} data-value="Tags">{I('Tags')} ({s.totalTags})</a></li>
							<li className={current.isEEContacts ? "current": ""}><a data-qa-id="search_menu_eecontacts" onClick={this.setCurrentSearch} data-value="ExternalExpertContacts">{I('External Expert Contacts')} ({s.totalExternalExpertContacts})</a></li>
							<li className={current.isCustomerNotes ? "current": ""}><a data-qa-id="search_menu_customernotes" onClick={this.setCurrentSearch} data-value="CustomerNotes">{I('Customer notes')} ({s.totalCustomerNotes})</a></li>
							<li className={current.isErrandNotes ? "current": ""}><a data-qa-id="search_menu_errandnotes" onClick={this.setCurrentSearch} data-value="ErrandNotes">{I('Internal Comments')} ({s.totalErrandNotes})</a></li>
						</ul>
					</div>
			</div>
	}
	searchSidebar = (hide) =>{
		let p = this.props, ui = p.ui;
		let totalErrands = p.searchResults.totalErrand;
		let totalCollabErrands = p.searchResults.totalCollabErrands;
		let totalAreas = p.searchResults.totalAreas;
		let totalTags = p.searchResults.totalTags;
		let totalKBs = p.searchResults.totalKBs;
		let totalCCs = p.searchResults.totalContacts;
		let totalEEContacts = p.searchResults.totalExternalExpertContacts;
		let totalCustomerNotes = p.searchResults.totalCustomerNotes;
		let totalErrandNotes = p.searchResults.totalErrandNotes;
		let current = p.searchFilter;

		let backNavHide = true;
		if(ui.showMobileView){
			if(ui.showErrandMobileView) {
				 backNavHide = false;
				if(ui.showSideBar){
					backNavHide = true;
				}
			}
		}
		let flexMenusStyle = {};
		if(ui.collapseSideBar) {
			flexMenusStyle = {padding: "0px"};
		}
		return(<WorkflowInnerContainerCtnr className="app-inner-content" hidden={hide} data-custom-scrollbar>
			<div className="flex-menus-wrap" style={flexMenusStyle}>
				<div className="side-bar-main-menu" hidden={features['hide-cention-logo']}>
					<span className="app-back-nav" hidden={backNavHide} onClick={this.toggleErrandList}>
						<i className="icon-chevron-left"></i>
					</span>
					{
						!ui.collapseSideBar &&
							<div style={{marginRight: "70px"}}><MenuCtnr visible={!ui.showErrandMobileView} pageContext={SEARCH_ERRANDS} /></div>
					}
					<ToggleSideBar visible={ui.showSideBar || ui.showMobileView}
						showSideBar={ui.showSideBar}
						collapsed={ui.collapseSideBar}
						mobileMode={ui.showMobileView}
						id={"QA_toggle_sidebar_top"}
						onCollapse={this.handleSideBarCollapse}
						onClick={this.handleSideBarToggle} />
				</div>
				{ !p.ui.collapseSideBar &&
					<ul className="sidebar-menu search">
						{/* Temporarily disable this Global Search feature till a full-fledged search feature is implemented */}
						{/* <li className={current.isGlobal ? "current": ""}><a data-qa-id="search_menu_global" onClick={this.setCurrentSearch} data-value="GlobalSearch">{I('Global search')}</a></li> */}
						<li className={current.isErrands ? "current": ""}><a data-qa-id="search_menu_errands" onClick={this.setCurrentSearch} data-value="Errands">{I('Errands')} ({totalErrands})</a></li>
						<li className={current.isCollaborations ? "current": ""}><a data-qa-id="search_menu_collaborations" onClick={this.setCurrentSearch} data-value="Collaborations">{I('Collaborations')} ({totalCollabErrands})</a></li>
						<li className={current.isContactCard ? "current": ""}><a data-qa-id="search_menu_contact" onClick={this.setCurrentSearch} data-value="CustomerContacts">{I('Customer Contacts')} ({totalCCs})</a></li>
						{
							features["library.admin"] &&
							<li className={current.isKnowledgeBase ? "current": ""}><a data-qa-id="search_menu_library" onClick={this.setCurrentSearch} data-value="KnowledgeBase">{I('Knowledge base')} ({totalKBs})</a></li>
						}
						<li className={current.isAreas ? "current": ""}><a data-qa-id="search_menu_areas" onClick={this.setCurrentSearch} data-value="Areas">{I('Areas')} ({totalAreas})</a></li>
						<li className={current.isTags ? "current": ""}><a data-qa-id="search_menu_tags" onClick={this.setCurrentSearch} data-value="Tags">{I('Tags')} ({totalTags})</a></li>
						<li className={current.isEEContacts ? "current": ""}><a data-qa-id="search_menu_eecontacts" onClick={this.setCurrentSearch} data-value="ExternalExpertContacts">{I('External Expert Contacts')} ({totalEEContacts})</a></li>
						<li className={current.isCustomerNotes ? "current": ""}><a data-qa-id="search_menu_customernotes" onClick={this.setCurrentSearch} data-value="CustomerNotes">{I('Customer notes')} ({totalCustomerNotes})</a></li>
						<li className={current.isErrandNotes ? "current": ""}><a data-qa-id="search_menu_errandnotes" onClick={this.setCurrentSearch} data-value="ErrandNotes">{I('Internal Comments')} ({totalErrandNotes})</a></li>
					</ul>
				}
				{
					p.ui.collapseSideBar &&
						<IconizeSideBarMenuCtnr
							showWorkflowMenu={true}
							currentPage={MM_SEARCH}
							onChangePage={p.onChangeAdminView} >
							<SearchCollapsibleMenu current={current} searchResults={p.searchResults} onClickMenu={this.setCurrentSearch} />
						</IconizeSideBarMenuCtnr>
				}
			</div>
			<SidebarSettingsCtnr />
		</WorkflowInnerContainerCtnr>)
	}
	wrap(match){
		return '<span class="search-line">'+match +'</span>';
	}
	highlightSearchTerm(term, searchResult){
		term = term.replace(/[+*?()[\]\\/]/gi, ''); //remove any accidental backslash
		let regex = new RegExp("" + term + "", 'gi');
		return searchResult.replace(regex, match => this.wrap(match));
	}
	// obsoleted
	getFilterLabel = (searchFilter) =>{
		if(searchFilter.filterByLabel.my)
			return FILTER_SEARCH_ERRAND_MY;
		else if(searchFilter.filterByLabel.all)
			return FILTER_SEARCH_ERRAND_ALL;
		else if(searchFilter.filterByLabel.close)
			return FILTER_SEARCH_ERRAND_CLOSE;
		else
			return FILTER_SEARCH_ERRAND_EMPTY;
	}
	handleFilterSearch = (newFilterBy) => {
		let prevFilterBy = this.props.searchFilter.filterBy
		if (newFilterBy === prevFilterBy) {
			newFilterBy = FILTER_SEARCH_ERRAND_EMPTY
		}
		this.props.handleFilterSearch(newFilterBy)
	}
	// obsoleted
	handleFilterLabelByMy = () =>{
		let my = this.props.searchFilter.filterByLabel.my;
		this.props.handleFilterLabelByMy(!my)
	}
	// obsoleted
	handleFilterLabelByAll = () =>{
		let all = this.props.searchFilter.filterByLabel.all;
		this.props.handleFilterLabelByAll(!all);
	}
	// obsoleted
	handleFilterLabelByClose = () =>{
		let close = this.props.searchFilter.filterByLabel.close;
		this.props.handleFilterLabelByClose(!close);
	}
	selectedAreasHadDueDate(){
		if(this.props.selectedErrands == null ||
			this.props.selectedErrands.length == 0 ){
			return false;
		}
		let areaMap = new Map();
		$.each(this.props.selectedErrands, (i,v) => {
			areaMap.set(v.areaId, v.areaId);
		});
		let orgs = this.props.allAreas
		let retVal = true;
		if(typeof orgs !== "undefined"){
			$.each(orgs, (i,v) => {
				if(v.Areas){
					$.each(v.Areas, (j,area) => {
						if(areaMap.has(area.Id) &&
							area.DueDateSelected == false){
							retVal = false;
							return;
						}
					});
				}
			});
		}
		return retVal;
	}
	errandSearchResults = (topResults, errands, searchTerm, searchFor) =>{
		let searchUI = this.props.searchUI;
		let searchFilter = this.props.searchFilter;
		let totalErrand = this.props.searchResults.totalErrand;
		let showPagination = searchUI.showAllErrand;
		let filteredTotal =  totalErrand;
		let filterByLabel = searchFilter.filterBy;
		let searchCheckboxStates = this.props.searchCheckboxStates;
		switch(filterByLabel) {
			case FILTER_SEARCH_ERRAND_MY:
				filteredTotal = this.props.searchResults.totalMyErrand;
				break;
			case FILTER_SEARCH_ERRAND_ALL:
				filteredTotal = this.props.searchResults.totalAllErrand;
				break;
			case FILTER_SEARCH_ERRAND_CLOSE:
				filteredTotal = this.props.searchResults.totalClosedErrand;
				break;
		}
		if (filteredTotal > topResults) {
			showPagination = true;
		}
		let ed = this.errandsRender(errands, topResults, searchTerm, this.props.wfSettings.activeUserId, totalErrand, filterByLabel);
		let currentPage = this.currentPageNumber(searchUI.topResults, searchUI.errandOffset);
		let fullWidth = "100%";
		if (this.props.totalSelected > 0) {
			fullWidth = "80%"
		}
		let allowDueDate = this.selectedAreasHadDueDate();
		const {
			wfSettings,
			...p
		} = this.props;
		let showAllErrands = null;
		let selectedAgent = 0, isCurrentAgent = false;
		if(this.props.selectedErrands != null &&
			this.props.selectedErrands.length > 0){
			$.each(this.props.selectedErrands, (i,v) => {
				if (this.props.wfSettings.activeUserId == v.agentId) {
					selectedAgent = 0;
					isCurrentAgent = true
				} else if (!isCurrentAgent) {
					selectedAgent = v.agentId
				}
			});
		}
		return(<div>
			<div className="search-title">{searchFor}</div>
			{(searchUI.showAllErrand || searchFilter.isErrands) &&
				<div className="search-errands">
					<ul className="search-errands-links">
						<li>
							<SquareCheckbox
								className="big"
								label={I('Select all')}
								checked={searchCheckboxStates.allSelected}
								onClick={this.handleSelectAll}
							/>
						</li>
						<li className={filterByLabel === FILTER_SEARCH_ERRAND_MY ? "current" : ""}>
							<a href="#" onClick={this.handleFilterSearch.bind(this, FILTER_SEARCH_ERRAND_MY)}>{I("My errands")} ({ed.myErrand})</a>
						</li>
						<li className={filterByLabel === FILTER_SEARCH_ERRAND_ALL ? "current" : ""}>
							<a href="#" onClick={this.handleFilterSearch.bind(this, FILTER_SEARCH_ERRAND_ALL)}>{I("All errands")} ({ed.allErrand})</a>
						</li>
						<li className={filterByLabel === FILTER_SEARCH_ERRAND_CLOSE ? "current" : ""}>
							<a href="#" onClick={this.handleFilterSearch.bind(this,FILTER_SEARCH_ERRAND_CLOSE)}>{I("Closed errands")} ({ed.closeErrand})</a>
						</li>
					</ul>
				</div>
			}
			<div>
				<div style={{float: "left", width: fullWidth}}>
					{ed.htmlRender}
					{showAllErrands}
					{showPagination && <CommonPagination
						pageCount={Math.ceil(filteredTotal / parseInt(searchUI.topResults, 10))}
						onPageChange={this.onChangePage}
						currentPageNum={currentPage}
						type='errand'
					/>}
				</div>
				{this.props.totalSelected > 0 && <WorkflowActions
					currentContext={SEARCH_ERRANDS}
					currentOpened={0}
					totalSelected={p.totalSelected}
					selectedErrands={p.selectedErrands}
					showHighPriorityFeature={wfSettings["highPriority"]}
					dueDateFeature={wfSettings["doneDateFeature"]}
					allowDueDate={allowDueDate}
					agentCanMoveFromInboxFeature={wfSettings["folders.move-from-new-errands"]}
					userFolders={wfSettings.userFolders}
					showMoveToFolder={p.ui.showMoveToFolder}
					agentCanForwardToAreaFeature={!wfSettings["agent.turn-off-forward-to-area-from-inbox"]}
					forwardToAreas={wfSettings.forwardToAreas}
					showForwardAreaOptions={p.ui.showForwardAreaOptions}
					allowAgentForwardToAgent={wfSettings["agentForwardToAgent"]}
					allowForwardErrandsToAgent={wfSettings["forwardErrandsToAgent"]}
					isLoadingFwdErrandsAgents={p.isLoadingFwdErrandsAgents}
					forwardErrandsAgents={p.forwardErrandsAgents}
					agentCanPutBackErrands={wfSettings["agentCanPutBackErrands"]}
					agentCanDeleteErrandsFeature={wfSettings["agent-delete-errands.admin-edit"]}
					shouldPopPrint={p.shouldPopPrint}
					hasChatErrand={false}
					hasClosedErrand={p.hasClosedErrand}
					onHandleSetPriority={p.onHandleSetPriority}
					onHandleSetDueDate={p.onHandleSetDueDate}
					onHandleMultipleErrandAction={p.onHandleMultipleErrandAction}
					onHandleMoveToFolder={p.onHandleMoveToFolder}
					onHandleForwardToArea={p.onHandleForwardToArea}
					onOpenForwardToAgent={p.onOpenForwardToAgent}
					onSelectForwardToAgent={p.onSelectForwardToAgent}
					onHandleReturnToInbox={p.onHandleReturnToInbox}
					onHandleSendingBulkErrand={p.onHandleSendingBulkErrand}
					onHandleLinkErrand={p.onHandleLinkErrand}
					onHandlePrintErrands={p.onHandlePrintErrands}
					onHandleDelete={p.onDeleteErrands}
					onHandleClose={p.onCloseErrands}
					myErrandsOnly={p.myErrandsOnly}
					handleAcquireFromOtherAgentErrand={p.handleAcquireFromOtherAgentErrand}
					selectedAgent={selectedAgent}
					agentWorking={p.agentWorking}
					/>
				}
			</div>
		</div>)
	}
	collabErrandSearchResults = (topResults, errands, searchTerm, searchFor) =>{
		let searchUI = this.props.searchUI;
		let searchFilter = this.props.searchFilter;
		let totalCollabErrands = this.props.searchResults.totalCollabErrands;
		let showPagination = searchUI.showAllErrand;
		let filteredTotal =  totalCollabErrands;
		let filterByLabel = searchFilter.filterBy;
		let searchCheckboxStates = this.props.searchCheckboxStates;
		switch(filterByLabel) {
			case FILTER_SEARCH_ERRAND_MY:
				filteredTotal = this.props.searchResults.totalCollabMyErrand;
				break;
			case FILTER_SEARCH_ERRAND_ALL:
				filteredTotal = this.props.searchResults.totalCollabAllErrand;
				break;
			case FILTER_SEARCH_ERRAND_CLOSE:
				filteredTotal = this.props.searchResults.totalCollabClosedErrand;
				break;
		}
		if (filteredTotal > topResults) {
			showPagination = true;
		}
		let ed = this.collabErrandsRender(errands, topResults, searchTerm, this.props.wfSettings.activeUserId, totalCollabErrands, filterByLabel);
		let currentPage = this.currentPageNumber(searchUI.topResults, searchUI.errandOffset);
		let fullWidth = "100%";
		if (this.props.totalSelected > 0) {
			fullWidth = "80%"
		}
		let allowDueDate = this.selectedAreasHadDueDate();
		const {
			wfSettings,
			...p
		} = this.props;
		let showAllErrands = null;
		let selectedAgent = 0, isCurrentAgent = false;
		if(this.props.selectedErrands != null &&
			this.props.selectedErrands.length > 0){
			$.each(this.props.selectedErrands, (i,v) => {
				if (this.props.wfSettings.activeUserId == v.agentId) {
					selectedAgent = 0;
					isCurrentAgent = true
				} else if (!isCurrentAgent) {
					selectedAgent = v.agentId
				}
			});
		}
		return(<div>
			<div className="search-title">{searchFor}</div>
			{(searchUI.showAllErrand || searchFilter.isCollaborations) &&
				<div className="search-errands">
					<ul className="search-errands-links">
						<li>
							<SquareCheckbox
								className="big"
								label={I('Select all')}
								checked={searchCheckboxStates.allSelected}
								onClick={this.handleSelectAll}
							/>
						</li>
						<li className={filterByLabel === FILTER_SEARCH_ERRAND_MY ? "current" : ""}>
							<a href="#" onClick={this.handleFilterSearch.bind(this, FILTER_SEARCH_ERRAND_MY)}>{I("My errands")} ({ed.collabMyErrand})</a>
						</li>
						<li className={filterByLabel === FILTER_SEARCH_ERRAND_ALL ? "current" : ""}>
							<a href="#" onClick={this.handleFilterSearch.bind(this, FILTER_SEARCH_ERRAND_ALL)}>{I("All errands")} ({ed.collabAllErrand})</a>
						</li>
						<li className={filterByLabel === FILTER_SEARCH_ERRAND_CLOSE ? "current" : ""}>
							<a href="#" onClick={this.handleFilterSearch.bind(this,FILTER_SEARCH_ERRAND_CLOSE)}>{I("Closed errands")} ({ed.collabCloseErrand})</a>
						</li>
					</ul>
				</div>
			}
			<div>
				<div style={{float: "left", width: fullWidth}}>
					{ed.htmlRender}
					{showAllErrands}
					{showPagination && <CommonPagination
						pageCount={Math.ceil(filteredTotal / parseInt(searchUI.topResults, 10))}
						onPageChange={this.onChangePage}
						currentPageNum={currentPage}
						type='collaboration'
					/>}
				</div>
				{this.props.totalSelected > 0 && <WorkflowActions
					currentContext={SEARCH_ERRANDS}
					currentOpened={0}
					totalSelected={p.totalSelected}
					selectedErrands={p.selectedErrands}
					showHighPriorityFeature={wfSettings["highPriority"]}
					dueDateFeature={wfSettings["doneDateFeature"]}
					allowDueDate={allowDueDate}
					agentCanMoveFromInboxFeature={wfSettings["folders.move-from-new-errands"]}
					userFolders={wfSettings.userFolders}
					showMoveToFolder={p.ui.showMoveToFolder}
					agentCanForwardToAreaFeature={!wfSettings["agent.turn-off-forward-to-area-from-inbox"]}
					forwardToAreas={wfSettings.forwardToAreas}
					showForwardAreaOptions={p.ui.showForwardAreaOptions}
					allowAgentForwardToAgent={wfSettings["agentForwardToAgent"]}
					allowForwardErrandsToAgent={wfSettings["forwardErrandsToAgent"]}
					isLoadingFwdErrandsAgents={p.isLoadingFwdErrandsAgents}
					forwardErrandsAgents={p.forwardErrandsAgents}
					agentCanPutBackErrands={wfSettings["agentCanPutBackErrands"]}
					agentCanDeleteErrandsFeature={wfSettings["agent-delete-errands.admin-edit"]}
					shouldPopPrint={p.shouldPopPrint}
					hasChatErrand={false}
					hasClosedErrand={p.hasClosedErrand}
					onHandleSetPriority={p.onHandleSetPriority}
					onHandleSetDueDate={p.onHandleSetDueDate}
					onHandleMultipleErrandAction={p.onHandleMultipleErrandAction}
					onHandleMoveToFolder={p.onHandleMoveToFolder}
					onHandleForwardToArea={p.onHandleForwardToArea}
					onOpenForwardToAgent={p.onOpenForwardToAgent}
					onSelectForwardToAgent={p.onSelectForwardToAgent}
					onHandleReturnToInbox={p.onHandleReturnToInbox}
					onHandleLinkErrand={p.onHandleLinkErrand}
					onHandlePrintErrands={p.onHandlePrintErrands}
					onHandleDelete={p.onDeleteErrands}
					onHandleClose={p.onCloseErrands}
					myErrandsOnly={p.myErrandsOnly}
					handleAcquireFromOtherAgentErrand={p.handleAcquireFromOtherAgentErrand}
					selectedAgent={selectedAgent}
					agentWorking={p.agentWorking}
					/>
				}
			</div>
		</div>)
	}
	currentPageNumber = (topResults, offset) =>{
		let currentPage = 0;
		let ofs = parseInt(offset, 10);
		if (ofs > 0 ){
			let tr = parseInt(topResults, 10);
			currentPage = Math.ceil(ofs/tr);
		}
		return currentPage;
	}
	onChangePage = (data, type) =>{
		let searchUI = this.props.searchUI;
		let selected = data.selected;
		let offset = Math.ceil(selected * searchUI.topResults);
		this.props.handlePaginate(offset, type, selected);
	}
	urlOpenAction(handler) {
		const { protocol, hostname } = window.location;
		return protocol + "//" + hostname + pathPrefix + handler;
	}
	handleLinkOnClick(){
		this.props.onHandleCloseSearchResultHeader();
	}
	getClientAvatar(fromId){
		let clientAvatar = this.props.clientAvatar.byId[fromId]
			, imgPath = ""
			, name = "";
		if(typeof clientAvatar !== "undefined"){
			if(clientAvatar.avatar){
				imgPath = clientAvatar.avatar.url;
			}
			name = clientAvatar.nameOnContactCard;
		}
		return {imgPath: imgPath, name: name};
	}
	onMouseEnter(id){
		if(this.props.previewActivated
			&& this.props.previewActivated.activate){
			if(this.props.previewActivated.instant){
				//this.setState({rowPreviewActive: id});
				this.props.onLoadPreview(id);
			}else{
				if(!this.props.previewActivated.instant && this.props.previewActivated.delaytime != null){
					this.timeout = setTimeout(function(){
						//this.setState({rowPreviewActive: id});
						this.props.onLoadPreview(id);
					}.bind(this), (this.props.previewActivated.delaytime*1000));
				}
			}
		}
	}
	onMouseLeave(event){
		let e = event.toElement || event.relatedTarget;
		if (e.parentNode == this || e == this) {
			return;
		}
		if(this.props.previewActivated
			&& this.props.previewActivated.activate){
			//this.props.setState({rowPreviewActive: ""});
			this.props.onLoadPreview(0);
			if(this.timeout){
				clearTimeout(this.timeout);
				this.timeout = null;
			}
		}
	}
	handleSelect = (id) => {
		let result = this.props.searchResults;
		let select = false;
		if (result.opr && result.opr[id]) {
			select = !result.opr[id].selected;
		}
		this.props.handleSelectErrand(id, select);
	}
	handleSelectAll(v) {
		this.props.handleSelectAllErrand(v);
	}
	errandsRender = (datas, topResults, searchTerm, activeUserId, totalErrand, filterBy) =>{
		const searchPrefix = "search-errands";
		let htmlRender = [];
		let t= 0
		, myErrand = this.props.searchResults.totalMyErrand
		, allErrand = this.props.searchResults.totalAllErrand
		, closeErrand = this.props.searchResults.totalClosedErrand
		, filteredDatas = datas
		;
		if (filteredDatas.length > 0){
			filteredDatas.forEach(el => {
				t++;
				if(t <= topResults){
					let body = el.body, subject="", previewData = "", ecsm = el.chatSatisfactionMeter;
					if(el.sourceId === Workflow.Errand.SERVICE_EMAIL || el.sourceId === Workflow.Errand.SERVICE_MANUAL){
						let sc = el.subject
						if(searchTerm !== "") {
							sc = this.highlightSearchTerm(searchTerm, sc);
							sc = '"' + sc + '"';
						}
						subject = <div className="search-errand-subject" dangerouslySetInnerHTML={{ __html: sc }}></div>;
					}
					if(body.length > 100){
						body = body.substring(0,100) + '...';
					}
					if(searchTerm !== "")
						body = this.highlightSearchTerm(searchTerm, body);
						body = '"' + body + '"';
					if(this.props.errandPreview){
						previewData = this.props.errandPreview;
					}
					let resizePreview = true;
					let previewInnerHeight = 100; //preview minimum height when there's no content
					if(this.state.previewDimensions && this.state.previewDimensions.height > 0) {
						previewInnerHeight = (this.state.previewDimensions.height + 100)+"px";
					}
					let result = this.props.searchResults;
					let selected = false;
					if (result.opr && result.opr[el.id]) {
						selected = result.opr[el.id].selected;
					}
					let tagMaxWidth = "300px", fromAreaWidth = "150px";
					if(!isDesktopMode()){
						if(this.props.ui.showSideBar){
							tagMaxWidth= "200px";
						}
					}
					let ellipsisStyle = {
						width: fromAreaWidth,
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden"
					}
					let ellipsisStyleAlternative = {
						width: "auto",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden"
					}
					let avatar = this.getClientAvatar(el.fromId);
					let cFrom = cleanChannelIdentifier(el.channel, el.from);
					let resultData = <Link key={"search-result-"+el.id} data-qa-id={"search-result-errand"} to={{pathname: `${pathPrefix}v5/open/search/`+ el.id, state: {search: `${pathPrefix}v5/search`, total: totalErrand }}} onClick={this.handleLinkOnClick}>
						<div key={el.id}>
							<div className="search-block">
								<div className="search-info-wrapper">
									<div className="section left">
										<div className="search-img">
											<ProfilePhoto photo={avatar.imgPath} isAgent={false} />
										</div>
										<div className="search-info">
											<div data-qa-id={searchPrefix + "-brief"} className="search-errand-name" title={el.from}>
												{avatar.name ? avatar.name : cFrom}
											</div>
											<div id={"preview_"+el.id} onMouseEnter={()=>this.onMouseEnter(el.id)} onMouseLeave={this.onMouseLeave}>
												{subject}
												<div className="search-errand-content" dangerouslySetInnerHTML={{ __html: body }}></div>
												<div style={{height: previewInnerHeight}} className={classNames("errand-preview", {"large" : resizePreview})} hidden={el.id === this.props.previewId ? false : true}>
													<div className="errand-preview-subject" dangerouslySetInnerHTML={{__html: el.subject}} />
													<div className={classNames("errand-preview-body", {"preview--16x9" : resizePreview})}>
													<Measure
														bounds
														onResize={contentRect => {
															this.setState({ previewDimensions: contentRect.bounds })
														}}
													>
													{({ measureRef }) => (
														<div ref={measureRef} className={classNames("prevInnerBody", {"preview__inner-wrapper" : resizePreview})}
															dangerouslySetInnerHTML={{__html: previewData}}></div>
													)}
													</Measure>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="section right">
										<SearchInfo
											label={I('Errand')}
											searchPrefix={searchPrefix+"-displayId"}
											value={"#"+el.displayId} />
										<SearchInfo
											label={I('Date')}
											searchPrefix={searchPrefix+"-createdTime"}
											value={el.createdTime} />
										<SearchInfo
											label={I('From')}
											searchPrefix={searchPrefix+"-from"}
											style={ellipsisStyleAlternative}
											value={cFrom} />
										<SearchInfo
											label={I('Closed')}
											searchPrefix={searchPrefix+"-closed"}
											value={(el.closedTime !== "" ? el.closedTime : I("Not answered"))} />
										<SearchInfo
											label={I('Area')}
											searchPrefix={searchPrefix+"-area"}
											value={el.area} />
										<SearchInfo
											label={I('Agent')}
											searchPrefix={searchPrefix+"-agent"}
											value={el.agent !== "" ? el.agent : I("Unassigned")} />
										<SearchInfo
											label={I('Channel')}
											searchPrefix={searchPrefix+"-channel"}
											value={el.channel} />
										<SearchInfo
											label={I('Tags')}
											searchPrefix={searchPrefix+"-tags"}
											style={ellipsisStyle}
											value={el.tags} />
										{el.sourceId === Workflow.Errand.SERVICE_CHAT && ecsm.rating !== "" && <div className="search-info">
											<div><label>{I('Client rating')}:</label><span data-qa-id={searchPrefix+"-client-rating"} className="satisfaction-icon">
													{ ecsm.rating == 1 ? <i className={"fa fa-thumbs-up"}></i> : null}
													{ ecsm.rating == 2 ? <i className={"fa fa-thumbs-down"}></i> : null}
											</span></div>
										</div>}
										{el.sourceId === Workflow.Errand.SERVICE_CHAT && ecsm.rating !== "" && <SearchInfo
											label={I('Client comments')}
											searchPrefix={searchPrefix+"-client-message"}
											value={ecsm.message} />}
									</div>
								</div>
							</div>
						</div>
					</Link>
					htmlRender.push(
						<div key={"search-result-row"+el.id} className="search-list-container">
							<div className="search-checkbox" style={{float: "left", width: "20px", marginTop: "30px"}}>
								<SquareCheckbox data-qa-id={"search-cb-"+el.id} onClick={()=>this.handleSelect(el.id)} checked={selected} />
							</div>
							{resultData}
						</div>)
				}
			});
		}
		return {htmlRender, myErrand, allErrand, closeErrand};
	}
	collabErrandsRender = (datas, topResults, searchTerm, activeUserId, totalCollabErrands, filterBy) =>{
		const searchPrefix = "search-collab-errands";
		let htmlRender = [];
		let t= 0
		, collabMyErrand = this.props.searchResults.totalCollabMyErrand
		, collabAllErrand = this.props.searchResults.totalCollabAllErrand
		, collabCloseErrand = this.props.searchResults.totalCollabClosedErrand
		, filteredDatas = datas
		;
		if (filteredDatas.length > 0){
			filteredDatas.forEach(el => {
				t++;
				if(t <= topResults){
					let body = el.body, subject="", previewData = "", ecsm = el.chatSatisfactionMeter;
					if(el.sourceId === Workflow.Errand.SERVICE_EMAIL || el.sourceId === Workflow.Errand.SERVICE_MANUAL){
						let sc = el.subject
						subject = <div className="search-collab-errand-subject" dangerouslySetInnerHTML={{ __html: sc }}></div>;
					}
					if(body.length > 100){
						body = body.substring(0,100) + '...';
					}
						body = '"' + body + '"';
					if(this.props.errandPreview){
						previewData = this.props.errandPreview;
					}
					let resizePreview = true;
					let previewInnerHeight = 100;
					if(this.state.previewDimensions && this.state.previewDimensions.height > 0) {
						previewInnerHeight = (this.state.previewDimensions.height + 100)+"px";
					}
					let result = this.props.searchResults;
					let selected = false;
					if (result.opr && result.opr[el.id]) {
						selected = result.opr[el.id].selected;
					}
					let tagMaxWidth = "300px", fromAreaWidth = "150px";
					if(!isDesktopMode()){
						if(this.props.ui.showSideBar){
							tagMaxWidth= "200px";
						}
					}
					let ellipsisStyle = {
						width: fromAreaWidth,
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden"
					}
					let ellipsisStyleAlternative = {
						width: "auto",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden"
					}
					let avatar = this.getClientAvatar(el.fromId);
					let cFrom = cleanChannelIdentifier(el.channel, el.from);
					let resultData = <Link key={"search-result-"+el.id} data-qa-id={"search-resultcollab-errand"} to={{pathname: `${pathPrefix}v5/open/search/`+ el.id, state: {search: `${pathPrefix}v5/search`, total: totalCollabErrands }}} onClick={this.handleLinkOnClick}>
						<div key={el.id}>
							<div className="search-block">
								<div className="search-info-wrapper">
									<div className="section left">
										<div className="search-img">
											<ProfilePhoto photo={avatar.imgPath} isAgent={false} />
										</div>
										<div className="search-info">
											<div data-qa-id={searchPrefix + "-brief"} className="search-collab-errand-name" title={el.from}>
												{avatar.name ? avatar.name : cFrom}
											</div>
											<div id={"preview_"+el.id} onMouseEnter={()=>this.onMouseEnter(el.id)} onMouseLeave={this.onMouseLeave}>
												{subject}
												<div className="search-collab-errand-content" dangerouslySetInnerHTML={{ __html: body }}></div>
												<div style={{height: previewInnerHeight}} className={classNames("collab-errand-preview", {"large" : resizePreview})} hidden={el.id === this.props.previewId ? false : true}>
													<div className="collab-errand-preview-subject" dangerouslySetInnerHTML={{__html: el.subject}} />
													<div className={classNames("collab-errand-preview-body", {"preview--16x9" : resizePreview})}>
													<Measure
														bounds
														onResize={contentRect => {
															this.setState({ previewDimensions: contentRect.bounds })
														}}
													>
													{({ measureRef }) => (
														<div ref={measureRef} className={classNames("prevInnerBody", {"preview__inner-wrapper" : resizePreview})}
															dangerouslySetInnerHTML={{__html: previewData}}></div>
													)}
													</Measure>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="section right">
										<SearchInfo
											label={I('Errand')}
											searchPrefix={searchPrefix+"-displayId"}
											value={"#"+el.displayId} />
										<SearchInfo
											label={I('Date')}
											searchPrefix={searchPrefix+"-createdTime"}
											value={el.createdTime} />
										<SearchInfo
											label={I('From')}
											searchPrefix={searchPrefix+"-from"}
											style={ellipsisStyleAlternative}
											value={cFrom} />
										<SearchInfo
											label={I('Closed')}
											searchPrefix={searchPrefix+"-closed"}
											value={(el.closedTime !== "" ? el.closedTime : I("Not answered"))} />
										<SearchInfo
											label={I('Area')}
											searchPrefix={searchPrefix+"-area"}
											value={el.area} />
										<SearchInfo
											label={I('Agent')}
											searchPrefix={searchPrefix+"-agent"}
											value={el.agent !== "" ? el.agent : I("Unassigned")} />
										<SearchInfo
											label={I('Channel')}
											searchPrefix={searchPrefix+"-channel"}
											value={el.channel} />
										<SearchInfo
											label={I('Tags')}
											searchPrefix={searchPrefix+"-tags"}
											style={ellipsisStyle}
											value={el.tags} />
										{el.sourceId === Workflow.Errand.SERVICE_CHAT && ecsm.rating !== "" && <div className="search-info">
											<div><label>{I('Client rating')}:</label><span data-qa-id={searchPrefix+"-client-rating"} className="satisfaction-icon">
													{ ecsm.rating == 1 ? <i className={"fa fa-thumbs-up"}></i> : null}
													{ ecsm.rating == 2 ? <i className={"fa fa-thumbs-down"}></i> : null}
											</span></div>
										</div>}
										{el.sourceId === Workflow.Errand.SERVICE_CHAT && ecsm.rating !== "" && <SearchInfo
											label={I('Client comments')}
											searchPrefix={searchPrefix+"-client-message"}
											value={ecsm.message} />}
									</div>
								</div>
							</div>
						</div>
					</Link>
					htmlRender.push(
						<div key={"search-result-row"+el.id} className="search-list-container">
							<div className="search-checkbox" style={{float: "left", width: "20px", marginTop: "30px"}}>
								<SquareCheckbox data-qa-id={"search-cb-"+el.id} onClick={()=>this.handleSelect(el.id)} checked={selected} />
							</div>
							{resultData}
						</div>)
				}
			});
		}
		return {htmlRender, collabMyErrand, collabAllErrand, collabCloseErrand};
	}
	// obsoleted
	filterErrandsByLabel = (datas, filterBy, activeUserId) =>{
		switch(filterBy){
			case FILTER_SEARCH_ERRAND_MY:
				return datas.filter(function(d, i){return d.agentId === activeUserId && d.closedTime === ""});
			case FILTER_SEARCH_ERRAND_ALL:
				return datas.filter(function(d, i){return d.agentId === 0 && d.closedTime === ""});
			case FILTER_SEARCH_ERRAND_CLOSE:
				return datas.filter(function(d, i){return d.closedTime !== ""});
		}
		return [];
	}
	knowledgeBaseRender = (datas, topResults, searchTerm) =>{
		let htmlRender = [], t= 0;
		if(datas.length > 0){
			datas.forEach(el => {
				t++;
				if(t <= topResults){
					let body = el.body;
					if(searchTerm !== "")
						body = this.highlightSearchTerm(searchTerm, el.body);
						htmlRender.push(
							<Link key={"search-result-lib-"+t+"-"+el.id} data-qa-id={"search-result-lib"} to={{pathname: `${pathPrefix}v5/admin/knowledge-bases/question/`+ el.id}}>
								<div className="search-block" key={el.id}>
									<div data-qa-id={"search-library-brief"} className="search-name search-library">
										<TruncateText truncateText={true}
											text={body} limit={120} showMore={false}/>
									</div>
									<div className="search-info">
										<div><label>{I('Date')}:</label><span data-qa-id={"search-library-createdTime"}>{el.createdTime}</span></div>
									</div>
								</div>
							</Link>
						);
				}
			});
		}
		return htmlRender;
	}
	KBSearchResults = (topResults, kbs, searchTerm, searchFor) =>{
		let searchUI = this.props.searchUI;
		let totalKBs = this.props.searchResults.totalKBs;
		let knowledgeBases = this.knowledgeBaseRender(kbs, topResults, searchTerm);
		let currentPage = this.currentPageNumber(searchUI.topResults, searchUI.kbOffset);
		return(<div>
			<div className="search-title">{searchFor}</div>
			{knowledgeBases}
			{!searchUI.showAllKB && <ShowAllItems
				total={totalKBs}
				searchFor={searchFor}
				type='knowledgebase'
				topResults={topResults}
				returnItemProps={this.handleReturnedProp}
			/>}
			{searchUI.showAllKB && <CommonPagination
				pageCount={Math.ceil(totalKBs / parseInt(searchUI.topResults, 10))}
				onPageChange={this.onChangePage}
				currentPageNum={currentPage}
				type='knowledgebase'
			/>}
		</div>)
	}
	contactCardRender = (datas, topResults, searchTerm) =>{
		const searchPrefix = "search-cc";
		const compStyle = {fontStyle: "italic"};
		let htmlRender = [], t= 0;
		if(datas.length > 0){
			datas.forEach(el => {
				t++;
				if(t <= topResults){
					let body = this.highlightSearchTerm(searchTerm, el.body);
					htmlRender.push(
						<Link key={"search-result-cc-"+t+"-"+el.id} data-qa-id={"search-result-cc"} to={{pathname: `${pathPrefix}admin.html#contactcards/`+ el.id}}>
							<div className="search-block" key={el.id}>
									<div className="search-img">
										<ProfilePhoto photo={el.avatar ? el.avatar : ""} isAgent={false}/>
									</div>
									<div className={"search-name "+searchPrefix}>
										<p data-qa-id={searchPrefix+"-name"} dangerouslySetInnerHTML={{__html: body}}></p>
										{el.company &&
											<p style={compStyle}>{el.company}</p>
										}
									</div>
									<div className={"search-info "+searchPrefix}>
										<div><label>{I('CONTACT SINCE')}:</label><span data-qa-id={searchPrefix+"-createdTime"}>{el.createdTime}</span></div>
									</div>
									<div className={"search-info "+searchPrefix}>
										<div><label>{I('CONTACT ID')}:</label><span data-qa-id={searchPrefix+"-email"}>{el.email}</span></div>
									</div>
									<div className={"search-info "+searchPrefix}>
										<div><label>{I('SERVICE')}:</label><span data-qa-id={searchPrefix+"-service"}>{el.service}</span></div>
									</div>
								</div>
						</Link>
						);
				}
			});
		}
		return htmlRender;
	}
	CCSearchResults = (topResults, ccs, searchTerm, searchFor) =>{
		let searchUI = this.props.searchUI;
		let totalContacts = this.props.searchResults.totalContacts;
		let contacts = this.contactCardRender(ccs, topResults, searchTerm);
		let currentPage = this.currentPageNumber(searchUI.topResults, searchUI.ccOffset);
		return(<div>
			<div className="search-title">{searchFor}</div>
			{contacts}
			{!searchUI.showAllContact && <ShowAllItems
				total={totalContacts}
				searchFor={searchFor}
				type='contactCard'
				topResults={topResults}
				returnItemProps={this.handleReturnedProp}
			/>}
			{searchUI.showAllContact && <CommonPagination
				pageCount={Math.ceil(totalContacts / parseInt(searchUI.topResults, 10))}
				onPageChange={this.onChangePage}
				currentPageNum={currentPage}
				type='contactCard'
			/>}
		</div>)
	}
	handleReturnedProp = (type) =>{
		this.props.handleShowAllErrand(type);
	}
	areaSearchResults = (topResults, areas, searchTerm, searchFor) =>{
		let searchUI = this.props.searchUI;
		let totalAreas = this.props.searchResults.totalAreas;
		let areaRender = this.areaRender(areas, topResults, searchTerm);
		let currentPage = this.currentPageNumber(searchUI.topResults, searchUI.areaOffset);
		return(<div>
			<div className="search-title">{searchFor}</div>
			{areaRender}
			{!searchUI.showAllArea && <ShowAllItems
				total={totalAreas}
				searchFor={searchFor}
				type='area'
				topResults={topResults}
				returnItemProps={this.handleReturnedProp}
			/>}
			{searchUI.showAllArea && <CommonPagination
				pageCount={Math.ceil(totalAreas / parseInt(searchUI.topResults, 10))}
				onPageChange={this.onChangePage}
				currentPageNum={currentPage}
				type='area'
			/>}
		</div>)
	}
	tagRender = (datas, topResults, searchTerm) =>{
		const searchPrefix = "search-tag";
		let htmlRender = [], t= 0;
		if(datas.length > 0){
			datas.forEach(el => {
				t++;
				if(t <= topResults){
					let body = this.highlightSearchTerm(searchTerm, el.body);
					htmlRender.push(
						<Link key={"search-result-tag-"+t+"-"+el.id} data-qa-id={"search-result-tag"} to={{pathname: `${pathPrefix}admin.html#tag/`+ el.id}}>
							<div className="search-block" key={el.id}>
								<div className="search-name search-tag">
									<p data-qa-id={searchPrefix+"-brief"} dangerouslySetInnerHTML={{__html: body}}></p>
								</div>
								<div className="search-info">
									<div>
										<label>{I('Areas')}:</label>
											<span data-qa-id={searchPrefix+"-area"}>
												{
													//tag area
												}
											</span>
									</div>
								</div>
								<div className="search-info">
									<div>
										<label>{I('Sub tags')}:</label>
										<span data-qa-id={searchPrefix+"-subtag"}>
											{
												//subtags
											}
										</span>
									</div>
								</div>
							</div>
						</Link>
						);
				}
			});
		}
		return htmlRender;
	}
	areaRender = (datas, topResults, searchTerm) =>{
		const searchPrefix = "search-areas";
		let htmlRender = [], t= 0;
		if(datas.length > 0){
			datas.forEach(el => {
				t++;
				if(t <= topResults){
					let body = this.highlightSearchTerm(searchTerm, el.body);
					htmlRender.push(
								<Link key={"search-result-area-"+t+"-"+el.id} data-qa-id={"search-result-area"} to={{pathname: `${pathPrefix}admin.html#areas/`+ el.id}}>
									<div className="search-block" key={el.id}>
										<div className={"search-name "+searchPrefix}>
											<p data-qa-id={searchPrefix+"-brief"} dangerouslySetInnerHTML={{__html: body}}></p>
										</div>
										<div className="search-info">
											<div><label>{I('Reply to email')}:</label><span data-qa-id={searchPrefix+"-email"}>{el.email}</span></div>
										</div>
										<div className="search-info">
											<div><label>{I('Has chat')}:</label><span data-qa-id={searchPrefix+"-hasChat"}>{el.hasChat}</span></div>
										</div>
										<div className="search-info">
											<div><label>{I('Organization')}:</label><span data-qa-id={searchPrefix+"-org"}>{el.organization}</span></div>
										</div>
										<div className="search-info">
											<div><label>{I('Timezone')}:</label><span data-qa-id={searchPrefix+"-tz"}>{el.timezone}</span></div>
										</div>
									</div>
								</Link>
							);
				}
			});
		}
		return htmlRender;
	}
	tagSearchResults = (topResults, tags,searchTerm,searchFor) =>{
		let searchUI = this.props.searchUI;
		let totalTags = this.props.searchResults.totalTags;
		let tagRender = this.tagRender(tags, topResults, searchTerm);
		let currentPage = this.currentPageNumber(searchUI.topResults, searchUI.tagOffset);
		return(<div>
			<div className="search-title">{searchFor}</div>
			{tagRender}
			{!searchUI.showAllTag && <ShowAllItems
				total={totalTags}
				searchFor={searchFor}
				type='tag'
				topResults={topResults}
				returnItemProps={this.handleReturnedProp}
			/>}
			{searchUI.showAllTag && <CommonPagination
				pageCount={Math.ceil(totalTags / parseInt(searchUI.topResults, 10))}
				onPageChange={this.onChangePage}
				currentPageNum={currentPage}
				type='tag'
			/>}
		</div>)
	}
	EESearchResults = (topResults, ees, searchTerm, searchFor) =>{
		let searchUI = this.props.searchUI;
		let totalExternalExpertContacts = this.props.searchResults.totalExternalExpertContacts;
		let eeContacts = this.eeContactRender(ees, topResults, searchTerm);
		let currentPage = this.currentPageNumber(searchUI.topResults, searchUI.eeOffset);
		return(<div>
			<div className="search-title">{searchFor}</div>
			{eeContacts}
			{!searchUI.showAllEEContact && <ShowAllItems
				total={totalExternalExpertContacts}
				searchFor={searchFor}
				type='eeContact'
				topResults={topResults}
				returnItemProps={this.handleReturnedProp}
			/>}
			{searchUI.showAllEEContact && <CommonPagination
				pageCount={Math.ceil(totalExternalExpertContacts / parseInt(searchUI.topResults, 10))}
				onPageChange={this.onChangePage}
				currentPageNum={currentPage}
				type='eeContact'
			/>}
		</div>)
	}
	eeContactRender = (datas, topResults, searchTerm) =>{
		const searchPrefix = "search-ee";
		let htmlRender = [], t= 0, isSearch = true;
		if(datas.length > 0){
			datas.forEach(el => {
				t++;
				if(t <= topResults){
					let body = this.highlightSearchTerm(searchTerm, el.email);
					htmlRender.push(
						<Link key={"search-result-ee-"+t+"-"+el.id} data-qa-id={"search-result-ee"} to={{pathname: `${pathPrefix}admin.html#externalexpert/address/`+ el.email + `/` + isSearch  + `/` + searchTerm}}>
							<div className="search-block" key={el.id}>
								<div className="search-img">
									<ProfilePhoto photo={el.avatar ? el.avatar : ""}/>
								</div>
								<div className={"search-name "+searchPrefix}>
									<p data-qa-id={searchPrefix+"-email"} dangerouslySetInnerHTML={{__html: body}}></p>
								</div>
								<div className={"search-info "+searchPrefix}>
									<div><label>{I('Added for areas')}:</label><span data-qa-id={searchPrefix+"-count"}>{el.count}</span></div>
								</div>
							</div>
						</Link>
						);
				}
			});
		}
		return htmlRender;
	}
	CCNotesSearchResults = (topResults, ccNotes, searchTerm, searchFor) =>{
		let searchUI = this.props.searchUI;
		let totalCustomerNotes = this.props.searchResults.totalCustomerNotes;
		let customerNotes = this.ccNotesRender(ccNotes, topResults, searchTerm);
		let currentPage = this.currentPageNumber(searchUI.topResults, searchUI.cnotesOffset);
		return(<div>
			<div className="search-title">{searchFor}</div>
			{customerNotes}
			{!searchUI.showAllCustomerNotes && <ShowAllItems
				total={totalCustomerNotes}
				searchFor={searchFor}
				type='custNotes'
				topResults={topResults}
				returnItemProps={this.handleReturnedProp}
			/>}
			{searchUI.showAllCustomerNotes && <CommonPagination
				pageCount={Math.ceil(totalCustomerNotes / parseInt(searchUI.topResults, 10))}
				onPageChange={this.onChangePage}
				currentPageNum={currentPage}
				type='custNotes'
			/>}
		</div>)
	}
	ccNotesRender = (datas, topResults, searchTerm) =>{
		const searchPrefix = "search-ee";
		let htmlRender = [],t = 0, time = 0, date2 = new Date(), isSearch = true;
		if(datas.length > 0){
			datas.forEach(el => {
				t++;
				let attachments = [];
				let created = "", date1 = new Date(el.writtendate);
				let timeDiff = Math.abs(date2.getTime() - date1.getTime());
				let diffDays = timeDiff / (1000 * 3600 * 24);
				if((diffDays % 1) >= 0.5) {
					time = Math.ceil(diffDays);
				} else {
					time = Math.floor(diffDays);
				}
				if(time == 0) {
					created = I("today");
				} else {
					created = time + " " + I("days ago");
				}
				if(t <= topResults){
					let body = el.note;
					let path = "";

					if(el.cgid !== 0){
						path = pathPrefix + "admin.html#contactcards/" + el.cgid + `/` + isSearch  + `/` + searchTerm;
					}

					if(searchTerm !== "")
						body = this.highlightSearchTerm(searchTerm, body);
					body = '"' + body + '"';
					$.each(el.attachments, (i,k) =>{
						attachments.push(
							<p className="customer-note-attachment" key={i}>
								<i className={'fa fa-paperclip file-icon'}
									aria-hidden="true" />
								<a className='link' href={location.origin + pathPrefix + k.downloadURL}
									target='_blank'
									data-qa-id={"attachment-"+k.fileName}
									>
									{k.fileName}
								</a>
								({humanByteSize(k.estSize)})
							</p>
						);
					});
					htmlRender.push(
						<div key={el.id}>
							<div className="search-block">
								<div className="search-img">
									<ProfilePhoto photo={this.getClientAvatar(el.fromId)} isAgent={false} />
								</div>
								<div className="search-info-wrapper">
									<div className="search-info">
										<Link key={"search-result-cc-"+t+"-"+el.id} data-qa-id={"search-result-cc"+t+"-"+el.id} to={{pathname: path}} className="search-img">
											<div data-qa-id={searchPrefix + "-name"} className="search-errand-name" title={el.name}>
												{el.name}
											</div>
											<div id={"preview_"+el.id} className="search-comments-content">
												<p data-qa-id={searchPrefix+"-note"} dangerouslySetInnerHTML={{__html: body}}></p>
											</div>
										</Link>
										<div id={"preview2_"+el.id}>
											{attachments}
											<p data-qa-id={searchPrefix+"-created"}>{I("Written by ")} {el.username}, {created}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				}
			});
		}
		return htmlRender;
	}
	ErrandNotesSearchResults = (topResults, eNotes, searchTerm, searchFor) =>{
		let searchUI = this.props.searchUI;
		let totalErrandNotes = this.props.searchResults.totalErrandNotes;
		let errandNotes = this.errandNotesRender(eNotes, topResults, searchTerm, totalErrandNotes);
		let currentPage = this.currentPageNumber(searchUI.topResults, searchUI.enotesOffset);
		return(<div>
			<div className="search-title">{searchFor}</div>
			{errandNotes}
			{!searchUI.showAllErrandNotes && <ShowAllItems
				total={totalErrandNotes}
				searchFor={searchFor}
				type='errandNotes'
				topResults={topResults}
				returnItemProps={this.handleReturnedProp}
			/>}
			{searchUI.showAllErrandNotes && <CommonPagination
				pageCount={Math.ceil(totalErrandNotes / parseInt(searchUI.topResults, 10))}
				onPageChange={this.onChangePage}
				currentPageNum={currentPage}
				type='errandNotes'
			/>}
		</div>)
	}
	errandNotesRender = (datas, topResults, searchTerm, totalErrandNotes) =>{
		const searchPrefix = "search-eNotes";
		let htmlRender = [],t = 0, time = 0, date2 = new Date();
		if(datas.length > 0){
			datas.forEach(el => {
				t++;
				let created = "", date1 = new Date(el.writtendate);
				let timeDiff = Math.abs(date2.getTime() - date1.getTime());
				let diffDays = timeDiff / (1000 * 3600 * 24);
				if((diffDays % 1) >= 0.5) {
					time = Math.ceil(diffDays);
				} else {
					time = Math.floor(diffDays);
				}
				if(time == 0) {
					created = I("today");
				} else {
					created = time + " " + I("days ago");
				}
				if(t <= topResults){
					let body = el.note
					if(searchTerm !== "")
						body = this.highlightSearchTerm(searchTerm, body);
					body = '"' + body + '"';
					htmlRender.push(
						<Link key={"search-result-"+el.id} data-qa-id={"search-result-ccNotes"} to={{pathname: `${pathPrefix}v5/open/search/`+ el.errandid, state: {search: `${pathPrefix}v5/search`, total: totalErrandNotes }}} onClick={this.handleLinkOnClick}>
							<div className="search-block" key={el.id} data-qa-id={"search-result-ccNotes"+el.id}>
								<div className="search-img">
									<ProfilePhoto photo={el.avatar ? el.avatar : ""} />
								</div>
								<div className="search-info-wrapper">
									<div className="search-info">
										<div data-qa-id={searchPrefix + "-name"} className="search-errand-name" title={el.username}>
											{el.username}
										</div>
										<div id={"preview_"+el.id} className="search-comments-content">
											<p data-qa-id={searchPrefix+"-note"} dangerouslySetInnerHTML={{__html: body}}></p>
											<p data-qa-id={searchPrefix+"-created"}>{I("Written ")} {created}</p>
										</div>
									</div>
									<div className={"search-info "+searchPrefix}>
										<div><label>{I('ERRAND')}:</label><span data-qa-id={searchPrefix+"-displayid"}>#{el.displayid}</span></div>
									</div>
								</div>
							</div>
						</Link>
					);
				}
			});
		}
		return htmlRender;
	}
};

class SearchCollapsibleMenu extends PureComponent {
	constructor(props) {
		super(props);
		this.handleShowSearchList = this.handleShowSearchList.bind(this);
		this.handleHideSearchList = this.handleHideSearchList.bind(this);

		this.state = {
			showCollapsedSearchFolder: false
		}
	}
	handleShowSearchList() {
		this.setState({ showCollapsedSearchFolder: true })
	}
	handleHideSearchList() {
		this.setState({ showCollapsedSearchFolder: false })
	}
	render() {
		const {
			current,
			searchResults,
			onClickMenu
		} = this.props;
		return <div className="collapse-tooltip-folders">
				<ul className="collapse-folders-list">
					<li className={current.isErrands ? "current": ""}><a data-qa-id="search_menu_errands" onClick={onClickMenu} data-value="Errands">{I('Errands')} ({searchResults.totalErrand})</a></li>
					<li className={current.isCollaborations ? "current": ""}><a data-qa-id="search_menu_collaborations" onClick={onClickMenu} data-value="Collaborations">{I('Collaborations')} ({searchResults.totalErrand})</a></li>
					<li className={current.isContactCard ? "current": ""}><a data-qa-id="search_menu_contact" onClick={onClickMenu} data-value="CustomerContacts">{I('Customer Contacts')} ({searchResults.totalContacts})</a></li>
					{
						features["library.admin"] &&
						<li className={current.isKnowledgeBase ? "current": ""}><a data-qa-id="search_menu_library" onClick={onClickMenu} data-value="KnowledgeBase">{I('Knowledge base')} ({searchResults.totalKBs})</a></li>
					}
					<li className={current.isAreas ? "current": ""}><a data-qa-id="search_menu_areas" onClick={onClickMenu} data-value="Areas">{I('Areas')} ({searchResults.totalAreas})</a></li>
					<li className={current.isTags ? "current": ""}><a data-qa-id="search_menu_tags" onClick={onClickMenu} data-value="Tags">{I('Tags')} ({searchResults.totalTags})</a></li>
					<li className={current.isEEContacts ? "current": ""}><a data-qa-id="search_menu_eecontacts" onClick={onClickMenu} data-value="ExternalExpertContacts">{I('External Expert Contacts')} ({searchResults.totalExternalExpertContacts})</a></li>
					<li className={current.isCustomerNotes ? "current": ""}><a data-qa-id="search_menu_customernotes" onClick={onClickMenu} data-value="CustomerNotes">{I('Customer notes')} ({searchResults.totalCustomerNotes})</a></li>
					<li className={current.isErrandNotes ? "current": ""}><a data-qa-id="search_menu_errandnotes" onClick={onClickMenu} data-value="ErrandNotes">{I('Internal Comments')} ({searchResults.totalErrandNotes})</a></li>
				</ul>
		</div>
	}
}

class CommonPagination extends Component{
	onHandle = (data) =>{
		this.props.onPageChange(data, this.props.type);
	}
	render = () =>{
		return(<ReactPaginate previousLabel={I("Prev")}
			data-qa-id={"search-pagination-"+this.props.type}
			nextLabel={I("Next")}
			breakLabel={"..."}
			breakClassName={"page-item"}
			pageCount={this.props.pageCount}
			marginPagesDisplayed={2}
			pageRangeDisplayed={5}
			forcePage={this.props.currentPageNum ? this.props.currentPageNum : 0}
			onPageChange={this.onHandle}
			containerClassName={"pagination justify-content-end search-pagination"}
			subContainerClassName={"pages pagination"}
			activeClassName={"page-item active"}
			previousClassName={"page-item"}
			previousLinkClassName={"page-link"}
			nextClassName={"page-item"}
			nextLinkClassName={"page-link"}
			pageLinkClassName={"page-link"}
		/>)
	}
}

class ShowAllItems extends Component{
	handleClick = (e) =>{
		this.props.returnItemProps(this.props.type);
	}
	render(){
		const{
			searchFor,
			topResults,
			total
		} = this.props;
		if(topResults >= total){
			return null;
		}
		return(
			<div className="show-all-search">
				<a data-qa-id={"search_show_all_"+searchFor} href='#' onClick={this.handleClick}>
					{I('Show all ')}{searchFor} ({total})
				</a>
			</div>
		)
	}
}
class ShowAllIErrand extends Component{
	handleClick = (e) =>{
		this.props.returnItemProps(this.props.type);
	}
	render(){
		const{
			total,
			searchFor,
			topResults
		} = this.props;
		if(topResults >= total){
			return null;
		}
		return(
			<div className="show-all-search">
				<a data-qa-id={"search_show_all_"+searchFor} href='#' onClick={this.handleClick}>
					{I('Show all ')}{searchFor} ({total})
				</a>
			</div>
		)
	}
}

export default Search;
